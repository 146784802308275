<template>
	<v-container class="h-100 .pb-55 mw-100 px-0 activity-log">
		<v-row class="mx-0">
			<v-col md="12" class="py-0">
				<v-layout style="min-height: 38px">
					<v-flex lg6 md6 sm6 class="my-auto">
						<div class="blue--text text--darken-4 listing-breadcrumb text-truncate">Activity Log</div>
					</v-flex>
					<v-flex lg6 md6 sm6 class="drive-action-btn text-right">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									v-bind="attrs"
									v-on="on"
									:disabled="loading"
									v-on:click="do_menu_action('export')"
									class="mx-2"
									color="blue darken-4"
									icon
								>
									<v-icon>mdi-file-excel-outline</v-icon>
								</v-btn>
							</template>
							<span>Export</span>
						</v-tooltip>
					</v-flex>
				</v-layout>
			</v-col>
			<v-col md="12" class="py-0">
				<v-layout class="border-top">
					<v-flex style="position: relative">
						<div
							style="max-height: calc(100vh - 125px); overflow: auto; padding-bottom: 60px"
							id="scrollable-div"
						>
							<div class="table-header">
								<v-layout>
									<v-flex class="my-auto font-level-3-bold pl-1" lg8 md8 sm8>
										<v-icon class="mr-2">mdi-format-list-text</v-icon>
										Description</v-flex
									>
									<v-flex class="my-auto font-level-3-bold text-center" lg2 md2 sm2>IP Address</v-flex>
									<v-flex class="my-auto font-level-3-bold text-center" lg2 md2 sm2>Date Time</v-flex>
								</v-layout>
								<v-progress-linear
									v-if="loading"
									indeterminate
									color="blue darken-4"
									class="drive-listing-loader"
								></v-progress-linear>
							</div>
							<template v-if="rows.length">
								<v-layout
									v-for="(log, rindex) in rows"
									:key="`log-${rindex}`"
									class="row-file light-border-top py-1 px-2"
								>
									<v-flex class="text-truncate my-auto font-level-3 d-flex align-items-center" lg8 md8 sm8>
										<v-img
											class="user-img mr-5"
											max-height="50"
											max-width="50"
											:src="log.added_by_profile"
										></v-img>
										<div class="d-flex flex-column flex-nowrap">
											<span v-html="log.description"></span>
										</div>
									</v-flex>
									<v-flex class="text-truncate my-auto font-level-3 text-center" lg2 md2 sm2>{{
										log.ip_address
									}}</v-flex>
									<v-flex class="text-truncate my-auto font-level-3 text-center" lg2 md2 sm2>{{
										formatDateTime(log.added_at)
									}}</v-flex>
								</v-layout>
							</template>
							<template v-else>
								<v-layout v-if="!loading">
									<v-flex class="my-auto font-level-3-bold py-3 text-center">No matching results</v-flex>
								</v-layout>
							</template>
						</div>
						<v-col md="12" class="pagination-wrapper" v-if="pagination.total_pages > 1">
							<v-pagination
								:disabled="loading"
								color="blue darken-4"
								v-model="pagination.current_page"
								v-on:input="update_pagination($event)"
								:length="pagination.total_pages"
								:total-visible="7"
							></v-pagination>
						</v-col>
					</v-flex>
				</v-layout>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import ObjectPath from "object-path";
import { QUERY } from "@/core/services/store/request.module";
import { SEARCH_ENABLED, SEARCH_TITLE } from "@/core/services/store/common.module";
import { EventBus } from "@/core/event-bus/event.bus";
import { last, head, pickBy, identity } from "lodash";
import JwtService from "@/core/services/jwt.service";

export default {
	data() {
		return {
			endpoint: "activity",
			pagination: {
				total_rows: null,
				total_pages: null,
				current_page: null,
				offset: null,
				page_limit: null,
			},
			rows: [],
			loading: false,
			filter: {
				"page-no": 1,
				"page-limit": 50,
				search: null,
				"start-date": null,
				"end-date": null,
			},
		};
	},
	watch: {
		$route() {
			this.get_rows({ do_export: false });
		},
	},
	methods: {
		do_menu_action(action) {
			switch (action) {
				case "export":
					this.get_rows({ do_export: true });
					break;
			}
		},
		update_pagination(param) {
			this.$router
				.push({
					name: this.endpoint,
					query: {
						...this.$route.query,
						t: new Date().getTime(),
						"page-no": param,
					},
				})
				.then(() => {
					this.get_rows({ do_export: false });
				});
		},
		get_rows({ do_export }) {
			if (this.loading) {
				return false;
			}

			this.loading = true;

			this.filter = { ...this.$route.query };

			this.filter["page-no"] = ObjectPath.get(this.$route, "query.page-no", 1) || 1;
			this.filter["page-limit"] = ObjectPath.get(this.$route, "query.page-limit", 20) || 20;
			this.filter["start-date"] = ObjectPath.get(this.$route, "query.start-date", null);
			this.filter["end-date"] = ObjectPath.get(this.$route, "query.end-date", null);

			this.filter["search"] = ObjectPath.get(this.$route, "query.search", null);
			this.filter["search-owner"] = ObjectPath.get(this.$route, "query.search-owner", null);
			this.filter["search-owned-by"] = ObjectPath.get(this.$route, "query.search-owned-by", null);
			this.filter["search-date-modified"] = ObjectPath.get(
				this.$route,
				"query.search-date-modified",
				null
			);
			this.filter["search-item-name"] = ObjectPath.get(this.$route, "query.search-item-name", null);
			this.filter["search-start-date"] = ObjectPath.get(this.$route, "query.search-start-date", null);
			this.filter["search-end-date"] = ObjectPath.get(this.$route, "query.search-end-date", null);

			this.filter["do-export"] = +!!do_export;

			if (do_export) {
				this.filter["token"] = JwtService.getToken();
				const filter = pickBy(this.filter, identity);
				const url_search_params = new URLSearchParams(filter).toString();
				const api_url = `${this.$apiURL}activity-log?${url_search_params}`;
				this.loading = false;
				window.open(api_url, "_blank");
				return false;
			}

			this.$store
				.dispatch(QUERY, { url: `activity-log`, data: this.filter })
				.then((response) => {
					this.rows = response.rows;
					this.pagination.total_rows = response["total"];
					this.pagination.total_pages = Math.ceil(response["total"] / response["page-limit"]);
					this.pagination.current_page = response["page-no"];
					this.pagination.offset = response["offset"];
					this.pagination.page_limit = response["page-limit"];
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.loading = false;
					this.$nextTick(() => {
						let scrollableDiv = document.getElementById("scrollable-div");
						scrollableDiv.scroll({ top: 0, behavior: "smooth" });
					});
				});
		},
	},
	beforeDestroy() {
		EventBus.$off("start-search");
		EventBus.$off("date-search");
		EventBus.$off("refresh-listing");
		EventBus.$emit("is-activity-log", false);
		this.$store.dispatch(SEARCH_ENABLED, false);
		this.$store.dispatch(SEARCH_TITLE, null);
	},
	mounted() {
		const _this = this;

		_this.get_rows({ do_export: false });

		_this.$store.dispatch(SEARCH_ENABLED, true);
		_this.$store.dispatch(SEARCH_TITLE, "Drive");

		EventBus.$emit("show-search-setting", true);
		EventBus.$emit("is-activity-log", true);

		EventBus.$on("refresh-listing", () => {
			_this.$nextTick(() => {
				_this.get_rows({ do_export: false });
			});
		});

		EventBus.$on("date-search", (param) => {
			_this.filter["start-date"] = head(param);
			_this.filter["end-date"] = last(param);
			_this.get_rows({ do_export: false });
		});

		EventBus.$on("start-search", (param) => {
			_this.filter.search = param;
			_this.$nextTick(() => {
				_this.get_rows({ do_export: false });
			});
		});
	},
};
</script>
